import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const Sliders = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="wrapper">
          <img src="/assets/images/slider-img3.png" alt="slider-img3" />
          <h3>Fahad Khan</h3>
          <p>Manager</p>
        </div>
        <div className="wrapper">
          <img src="/assets/images/slider-img2.png" alt="slider-img2" />
          <h3>Fahad Khan</h3>
          <p>Manager</p>
        </div>{" "}
        <div className="wrapper">
          <img src="/assets/images/slider-img1.png" alt="slider-img1" />
          <h3>Fahad Khan</h3>
          <p>Manager</p>
        </div>
      </Slider>
    </div>
  );
};

export default Sliders;
